import React, { useEffect } from 'react';
import WebinarPage from '../components/webinar-page';
import '../styles/webinar-page.css';
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarFromConcept = () => {
  //
  const webinarUrl = "https://webinarkit.com/webinar/registration/oneclick/67bc3847e2aace524737af02?date=jit_15";
  const title = "2025 Patent Licensing Roadmap: From Concept to Cash";
  const canonical = "/webinar-from-concept-to-commercialization-the-licensing-pathway";
  const lessons = [
    "The stages of moving from concept to market through licensing",
    "Key considerations in IP licensing agreements.",
    "Strategies for negotiating and maximizing licensing deals.",
    "Understanding the risks and rewards of IP commercial ventures."
  ]
  const btnTxt = "Watch Webinar Now";
  const introAuthor = "Bao Tran is an expert in IP commercialization and licensing at PatentPC."
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <>
    <WebinarPage webinarUrl={webinarUrl} introAuthor={introAuthor} title={title} canonical={canonical} lessons={lessons} btnTxt={btnTxt}></WebinarPage>
    </>
  )
};

export default WebinarFromConcept;